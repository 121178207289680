














import Vue, { PropType } from 'vue';
import { getDateText } from '@devhacker/shared/utils/date';
import TheLink from '~/components/TheLink.vue';
import { getRelativeLink } from '~/utils';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

export default Vue.extend({
  name: 'NewsWidgetItem',

  components: {
    TheLink,
  },

  props: {
    item: {
      type: Object as PropType<IArticle>,
      required: true,
    },
  },

  computed: {
    date (): string {
      return getDateText(this.item.date);
    },
    isActive (): boolean {
      return getRelativeLink(this.item.url) === this.$route.path;
    },
  },

  methods: {
    sendEvent () {
      this.$sendAnalyticsEvent({
        event: 'Выбор материала_блок новостей',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: getRelativeLink(this.item.url),
        element: '',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },
  },
});
