









import Vue, { PropOptions } from 'vue';
import { isTheRecipeAmpPage } from '../router/index';
import { isAmpPage } from '~/router/index';
import { getRelativeLink } from '~/utils';

export default Vue.extend({
  name: 'TheLink',

  props: {
    link: {
      type: String,
      required: true,
    } as PropOptions<string>,

    title: {
      type: String,
      required: false,
      default: '',
    } as PropOptions<string>,

    backgroundColor: {
      type: String,
      default: '',
    } as PropOptions<string>,

    isActive: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
  },
  computed: {
    componentProps (): {
      readonly href: string,
      readonly name: string,
      readonly style: any
      readonly 'data-vars-link-href'?: string;
      } {
      const isAmp = isAmpPage(this.$route?.name) || isTheRecipeAmpPage(this.$route?.name);
      const href = getRelativeLink(this.link);

      return {
        href,
        name: this.title,
        style: this.isActive ? { 'background-color': this.backgroundColor } : null,
        'data-vars-link-href': isAmp ? href : undefined,
      };
    },
  },
});
