








import Vue, { PropType } from 'vue';
import { getFullUrl } from '~/utils';

export default Vue.extend({
  name: 'NavMenuSocialsItem',
  props: {
    social: {
      required: true,
      type: Object as PropType<MenuItemWithIcon>,
    },
  },
  methods: {
    getAnalyticsLink (): string {
      return getFullUrl(this.social.link);
    },
  },
});
