









import Vue from 'vue';

import { getPushNotificationsManager } from '~/plugins/push-notifications.client';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import isNotificationsSupported from '~/utils/isNotificationsSupported';

export default Vue.extend({
  name: 'PushControlButton',

  computed: {
    pushControlTitle (): string {
      return this.$store.state.isSubscribedToWebPushNotifications
        ? 'Вы подписаны на push'
        : 'Вы не подписаны на push';
    },

    isPushControlVisible (): boolean {
      return isNotificationsSupported();
    },
  },

  methods: {
    handlePushControlClick (): void {
      const pushNotificationsManager = getPushNotificationsManager(
        this.$store,
        this.$pushApi,
      );

      if (this.$store.state.isSubscribedToWebPushNotifications) {
        pushNotificationsManager.unsubscribe();
        this.$sendAnalyticsEvent({
          event: 'Подписаться_уведомления о статьях',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: '',
          element: 'Футер',
          item: 'Отписаться',
          action: '',
          value: 1,
          currency: 'piece',
        });
      } else {
        this.$sendAnalyticsEvent({
          event: 'Подписаться_уведомления о статьях',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: '',
          element: 'Футер',
          item: 'Подписаться',
          action: '',
          value: 1,
          currency: 'piece',
        });

        pushNotificationsManager.subscribe();
      }
    },
  },
});
