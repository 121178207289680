import { render, staticRenderFns } from "./TheHeader.amp.vue?vue&type=template&id=68dec3f6&scoped=true&lang=pug&"
import script from "./TheHeader.amp.vue?vue&type=script&lang=ts&"
export * from "./TheHeader.amp.vue?vue&type=script&lang=ts&"
import style0 from "./TheHeader.amp.vue?vue&type=style&index=0&id=68dec3f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68dec3f6",
  null
  
)

export default component.exports