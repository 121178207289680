









import Vue, { PropOptions } from 'vue';

// @ts-ignore

import { isHomeLink, getRelativeLink, getFullUrl } from '~/utils';

import { HOME_URL, WORDPRESS_API_URL } from '~/constants/config';
import HeaderArrowMenu from '~/components/HeaderArrowMenu.vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
export default Vue.extend({
  name: 'PrimaryMenu',

  components: {
    HeaderArrowMenu,
  },

  props: {
    location: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    } as PropOptions<string>,
    primaryNavMenuItems: {
      type: Array,
      required: true,
    } as PropOptions<Array<MenuItem>>,
  },

  computed: {
    primaryMenuClass (): any {
      return this.location ? `primary-menu--${this.location}` : null;
    },
    primaryMenuTheme (): string {
      return this.location === 'mobile' ? 'light' : 'red';
    },
    items (): Array<MenuItem> {
      return this.primaryNavMenuItems.map((item) => {
        const result = { ...item };

        result.isInternalUrl = isHomeLink(item.link);
        result.isActive = this.isActive(item.link);
        result.link = result.isInternalUrl
          ? getRelativeLink(item.link)
          : item.link;

        return result;
      });
    },
  },

  methods: {
    isActive (link: string): boolean {
      const linkWithoutHostName = this.getLinkWithHostName(link);

      // #1nuu6ab
      // адреса для рецептов имеют вид: /recipe/[slug]
      // адрес главной рецептов имеет вид: /recipes/
      // в случае если это рецепт - здесь надо возвращать true
      const recipeRegex = /^\/recipe[s]?\//g;
      // если текущий route.path - рецепт
      // присваем routePath значение '/recipes/'
      // чтобы совпадало со ссылкой в меню, пришедшей с сервера
      const routePath = recipeRegex.test(this.$route.path)
        ? '/recipes/'
        : this.$route.path;

      return routePath === linkWithoutHostName;
    },

    getLinkWithHostName (link: string): string {
      return link.replace(WORDPRESS_API_URL, '').replace(HOME_URL, '');
    },

    onClickMenuItem (item: MenuItem): void {
      this.$sendAnalyticsEvent({
        event: 'Клик_хедер',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: getSlugFromUrl(item.link),
        element: 'Меню 1 уровня',
        item: item.title,
        action: '',
        value: 1,
        currency: 'piece',
      });

      this.$sendYandexMetrika({
        level1: 'Клик_Хедер',
        level4: getFullUrl(item.link),
        level5: item.title,
        level6: 'Первый уровень',
      });
    },
  },
});
