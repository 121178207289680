






















import Vue, { PropType } from 'vue';
import NavMenuCategoriesItem from './NavMenuCategoriesItem.vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { getFullUrl } from '~/utils';

export default Vue.extend({
  name: 'NavMenuCategories',
  components: {
    NavMenuCategoriesItem,
  },
  props: {
    burgerMenuItems: {
      required: true,
      type: Array as PropType<Array<MenuItem>>,
    },
  },
  methods: {
    onClickMenuItem (categoryTitle: string, linkMenuItem: string): void {
      this.$sendAnalyticsEvent({
        event: 'Клик_хедер',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: getSlugFromUrl(linkMenuItem),
        element: 'Бургер-меню',
        item: categoryTitle,
        action: '',
        value: 1,
        currency: 'piece',
      });

      this.$sendYandexMetrika({
        level1: 'Клик_Хедер',
        level4: getFullUrl(linkMenuItem),
        level5: categoryTitle,
        level6: 'Бургер-меню',
      });
    },
  },
});
