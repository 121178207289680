































import Vue from 'vue';
// @ts-ignore
import { mapGetters } from 'vuex';
import MascotSpeech from './MascotSpeech.vue';
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { createScriptIfNeeded } from '~/utils/embeds';

export default Vue.extend({
  name: 'MascotFooter',

  components: {
    IntersectionObserver,
    MascotSpeech,
  },

  data () {
    return {
      animation: null as null | any,
      showMenu: false as boolean,
      hovered: false as boolean,
      containerHidden: false as boolean,
    };
  },

  computed: {
    ...mapGetters({
      mascot: 'mascotFooterInfo',
    }),

    containerClass (): Record<string, boolean> {
      return {
        'container--hovered': this.hovered || this.showMenu,
      };
    },

    classHoveredAnimation (): Record<string, boolean> {
      return {
        'mascot-footer__animation--hovered': this.hovered || this.showMenu,
      };
    },
  },

  watch: {
    $route () {
      this.showMenu = false;
      this.containerHidden = false;
      this.hovered = false;
    },
  },

  async mounted () {
    await createScriptIfNeeded('/js/lottie.min.js');

    const animationData = await import('~/lottie-animations/lazeyka-top-crop.json');

    this.animation = window?.lottie?.loadAnimation({
      container: this.$refs.animationContainer,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData,
      initialSegment: [0, 450],
    });
  },

  methods: {
    sendAnalytics (
      event: string,
      slug_location: string = '',
      slug_referrer: string = '',
      action: string = '',
    ) {
      const userId = (this.$store.state as IRootState).userIntegerId;
      this.$sendAnalyticsEvent({
        event,
        slug_location,
        slug_referrer,
        element: 'Футер',
        item: '',
        action,
        value: 1,
        user_id: userId ?? '',
        currency: 'piece',
      });
    },

    playAnimation () {
      if (this.animation) {
        this.animation.play();
        this.animation.loop = true;
      }
    },

    handleLinkClick () {
      this.sendAnalytics(
        'Лазейка_клик по кнопке',
        getSlugFromUrl(this.$route.fullPath),
        this.mascot.link,
        '',
      );
    },

    handleClose () {
      this.containerHidden = true;

      this.sendAnalytics('Лазейка_закрытие', '', '', 'клик на крестик');
    },

    handleMouseover () {
      if (this.showMenu) {
        return;
      };

      if (this.animation.currentFrame < 350) {
        this.animation.pause();
      } else {
        this.animation.loop = false;
      }

      this.hovered = true;
    },

    handleMouseleave () {
      this.playAnimation();
      this.hovered = this.showMenu ?? false;
    },

    handleClick () {
      this.playAnimation();
      this.showMenu = true;

      this.sendAnalytics('Лазейка_клик', getSlugFromUrl(this.$route.fullPath));
    },
  },
});
