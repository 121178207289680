

























import Vue, { PropType } from 'vue';
import { getRelativeLink } from '~/utils';

export default Vue.extend({
  name: 'NewsFavorite',
  props: {
    count: {
      default: 0,
      type: Number as PropType<number>,
    },
    url: {
      default: '',
      type: String as PropType<string>,
    },
    handlerMouseover: {
      required: true,
      type: Function as PropType<Function>,
    },
    handlerMouseleave: {
      required: true,
      type: Function as PropType<Function>,
    },
    handlerClick: {
      required: true,
      type: Function as PropType<Function>,
    },
  },

  methods: {
    onClickCommentsIcon () {
      this.$sendAnalyticsEvent({
        event: 'Новости_переход в новости',
        slug_location: getRelativeLink(this.$route.fullPath),
        slug_referrer: getRelativeLink(this.url),
        element: 'Иконка комментариев',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },
  },
});
