





























































































import Vue from 'vue';
// @ts-ignore
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
import NewsFavorite from '~/components/NewsMain/NewsFavorite.vue';
import {
  FETCH_COMMENTS_COUNT,
  FETCH_VIEWS_COUNT,
} from '~/constants';

import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import {
  getEditLinkHref, getRelativeLink,
} from '~/utils';
const capitalize = (str: string = ''): string => str.length > 0 ? str.charAt(0).toUpperCase() +
str.slice(1) : str;
type ArticleWithComments = IArticle & {
  comment_count: number,
  isHover: boolean
};
export default Vue.extend({
  name: 'NewsMain',
  components: {
    NewsFavorite,
    AdminControlsPanel,
  },
  mixins: [UpdatingViewsCountMixin],
  data () {
    return {
      articles: [] as ArticleWithComments[],
      link: '' as string,
      desktopMedia: {} as MediaQueryList,
      isDesktop: false as boolean,
    };
  },
  async fetch (): Promise<void> {
    await this.getArticles();
  },
  computed: {
    ...mapState(['isAuthenticatedInWordpress']),
    ...mapGetters([
      'getCommentsCount',
      'getViewsCount',
    ]),
    lead (): ArticleWithComments | null {
      return this.articles.length > 0 ? this.articles[0] : null;
    },
    news (): (ArticleWithComments | any)[] {
      return this.articles.length > 0 ? this.articles.slice(1) : [];
    },
  },
  mounted () {
    this.desktopMedia = window.matchMedia('(min-width: 1280px)');
    const handler = (e: { matches: boolean}) => {
      this.isDesktop = e.matches;
    };
    handler(this.desktopMedia);

    this.desktopMedia.addEventListener('change', handler);
    this[FETCH_VIEWS_COUNT](this.articles.map(el => el.id));
    this.getComments();
  },
  methods: {
    ...mapActions({
      FETCH_COMMENTS_COUNT,
      FETCH_VIEWS_COUNT,
    }),

    getEditLinkHref,

    capitalize,

    getArticles (): Promise<any> {
      return this.$wordpressApi.fetchLastNArticles(1)
        .then(({ status, data }) => {
          if (status === 200) {
            this.articles = data.news_block.items
              .map(({ title, url, id, name }: IArticle) => ({ title, url, id, name, isHover: false }));
            this.link = data.news_block.link;
          }
          // eslint-disable-next-line no-console
          console.error('Error while fetchLastNArticles(5) (NewsMain.vue)');
          throw new Error('Error while fetchLastNArticles(5)');
        })
        .catch(() => [])
        .then(() => this.getComments());
    },
    getComments () {
      return this.$commentsApi.fetchCommentsCount(this.articles.map(el => el.id))
        .then((resp: any) => {
          if (resp.status === 200) {
            this.articles.forEach((el) => {
              const value = resp.data.data.find((_el: { post_ID: number, count: number}) => _el.post_ID === el.id);
              Vue.set(el, 'comment_count', value ? value.count : 0);
            });
          }
        })
        .catch(() => []);
    },
    clickHandlerItem (e: any, slug: string) {
      e.preventDefault();
      location.href = slug;
    },

    clickHandlerLead (e: any, slug: string) {
      e.preventDefault();

      this.$sendAnalyticsEvent({
        event: 'Новости_переход в новости',
        slug_location: getRelativeLink(this.$route.fullPath),
        slug_referrer: slug,
        element: 'Акцентная карточка',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });

      location.href = slug;
    },

    clickHandlerAll (e: any, slug: string) {
      e.preventDefault();
      location.href = slug;
    },

    clickHandlerComment (slug: string) {
      return (e: any) => {
        e.preventDefault();
        location.href = slug;
      };
    },
    mouseoverLead () {
      if (this.isDesktop) {
        this.articles[0].isHover = true;
      }
    },

    mouseleaveLead () {
      if (this.isDesktop) {
        this.articles[0].isHover = false;
      }
    },

    mouseoverList (item: ArticleWithComments) {
      return () => {
        if (this.isDesktop) {
          item.isHover = true;
        }
      };
    },

    mouseleaveList (item: ArticleWithComments) {
      return () => {
        if (this.isDesktop) {
          item.isHover = false;
        }
      };
    },

    onClickAllNews () {
      this.$sendAnalyticsEvent({
        event: 'Новости_переход в новости',
        slug_location: getRelativeLink(this.$route.fullPath),
        slug_referrer: '/topics/news/',
        element: 'Все новости',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },
  },
});
